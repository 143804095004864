import { BrowserRouter } from 'react-router-dom';
import "./App.scss";
import Routing from './routing/Routing';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import 'react-image-gallery/styles/css/image-gallery.css';
const  App = () => {
  return (
    <>
    <div className="App">
      <BrowserRouter>
        <Routing />
        <ToastContainer />
      </BrowserRouter>
    </div>
    </>
  );
}

export default App;
