import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "./../category/CategoryList.scss";

const DeleteProductModal = ({ show, handleClose, handleDelete, loading }) => {
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirm Disable</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" />
					</div>
				) : (
					<p>
						Are you sure you want to disable this product? This action cannot
						be undone.
					</p>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose} disabled={loading}>
					Cancel
				</Button>
				<Button
					variant="danger"
					onClick={handleDelete}
					className="btnPrimary"
					disabled={loading}
				>
					{loading ? "Disable..." : "Disable"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteProductModal;
