import { CONSTANT_URL } from "./apiEndPoint";
import { adminServiceApiCall } from "./adminService";
import { productServiceApiCall } from "./productService";
import { userServiceApiCall } from "./userService";
export const CREATE_CATEGORY = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.CREATE_CATEGORY,payload)
}

export const CATEGORY_LIST = async (payload) => {
    let query = `?page=${payload.page}`;
    if (payload.isSubCategoryRequest) {
        query += `&isSubCategoryRequest=${payload.isSubCategoryRequest}`;
    }

    return await adminServiceApiCall("get", CONSTANT_URL.CATEGORY_LIST + query);
};


export const DELETE_CATEGORY = async(payload)=> {
    return await adminServiceApiCall("delete",CONSTANT_URL.DELETE_CATEGORY,payload)
}

export const UPDATE_CATEGORY = async(payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.UPDATE_CATEGORY,payload)
}

export const CREATE_SUBCATEGORY = async (payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.CREATE_SUBCATEGORY,payload)
}

export const SUBCATEGORY_LIST = async (payload) => {
    const page=`?page=${payload.page}`
    return await adminServiceApiCall("get",CONSTANT_URL.SUBCATEGORY_LIST+ page)
}

export const DELETE_SUBCATEGORY = async(payload)=> {
    return await adminServiceApiCall("delete",CONSTANT_URL.DELETE_SUBCATEGORY,payload)
}

export const UPDATE_SUBCATEGORY = async(payload) => {
    return await adminServiceApiCall("post",CONSTANT_URL.UPDATE_SUBCATEGORY,payload)
}
export const CREATE_PRODUCT = async(payload) => {
    return await productServiceApiCall("post",CONSTANT_URL.CREATE_PRODUCT,payload)
}

export const GET_SUBCATEGORY_BY_CATEGORYID = async(categoryId) => {
    const page = `?categoryId=${categoryId}`
    return await userServiceApiCall("get",CONSTANT_URL.GET_SUBCATEGORY_BY_CATEGORY+page)
}
export const GET_PRODUCT_LIST = async(payload) => {
    const page = `?page=${payload.page}&limit=${payload.limit}`
    return await adminServiceApiCall("get",CONSTANT_URL.GET_PRODUCT_LIST+page)
}

export const UPDATE_PRODUCT = async (payload,id) => {
    const page = `?productId=${id}`
    return await productServiceApiCall("put",CONSTANT_URL.UPDATE_PRODUCT+page,payload)
}

export const DELETE_PRODUCT = async(id) => {
    const page=`?productId=${id}`
    return await productServiceApiCall("delete",CONSTANT_URL.DELETE_PRODUCT+page)
}

export const VERIFY_PRODUCT_IMAGES = async (id) => {
    return await adminServiceApiCall("post", CONSTANT_URL.PRODUCT_IMAGE_VALIDATION,id)
}


