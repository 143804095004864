import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FAQ_LIST } from "../../service/faqApi";
import { useNavigate } from "react-router-dom";

const FaqList = () => {
	const navigate =  useNavigate()
	const [faqList, setFaqList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(false); // Add loading state

	const handleEditClick = (id) => {
		navigate(`/dashboard/edit-faq/${id}`);
	};

	const handleDeleteClick = (category) => {
		setSelectedCategory(category);
		setShowDeleteModal(true);
	};
	const getFaqList = async (page) => {
		setLoading(true); // Start loading
		try {
			const { data } = await FAQ_LIST({ page });
			setFaqList(data.result.faqlist);
			setTotalPages(Number(data.result.pagination.totalPages));
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false); // End loading
		}
	};

	useEffect(() => {
		getFaqList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th> {/* Serial Number Column */}
						<th scope="col">Category Type</th>
						<th scope="col">Question Type</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="4" className="text-center">
								Loading...
							</td>
						</tr>
					) : faqList.length > 0 ? (
						faqList.map((category, index) => (
							<tr key={category.id}>
								<td>{(currentPage - 1) * faqList.length + (index + 1)}</td>{" "}
								{/* Calculate Serial Number */}
								<td>{category.categorytype}</td>
								<td>{category.questiontype}</td>
								<td>
									<button
										className="btn btn-primary btnPrimary btn-sm"
										onClick={() => handleEditClick(category.id)}
										style={{ marginRight: "5px" }}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
										onClick={() => handleDeleteClick(category)}
									>
										Delete
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No categories found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<nav>
					<ul className="pagination justify-content-center">
						{[...Array(totalPages).keys()].map((page) => (
							<li
								className={`page-item ${
									page + 1 === currentPage ? "active" : ""
								}`}
								key={page}
							>
								<button
									className="page-link"
									onClick={() => handlePageChange(page + 1)}
								>
									{page + 1}
								</button>
							</li>
						))}
					</ul>
				</nav>
			)}
		</div>
	);
};

export default FaqList;
