import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import "./../category/CategoryList.scss";

const VerifyProductImageModal = ({ show, handleClose, handleVerify, loading }) => {
	return (
		<Modal show={show} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title>Confirm Verify</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{loading ? (
					<div className="d-flex justify-content-center">
						<Spinner animation="border" />
					</div>
				) : (
					<p>
						Are you sure you want to verify this product image? This action cannot
						be undone.
					</p>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleClose} disabled={loading}>
					No
				</Button>
				<Button
					variant="success"
					onClick={handleVerify}
					className="btnPrimary"
					disabled={loading}
				>
					{loading ? "loading..." : "Yes"}
				</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default VerifyProductImageModal;
