import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
	CATEGORY_LIST,
	UPDATE_CATEGORY,
	DELETE_CATEGORY,
} from "../../service/productApi";
import EditCategoryModal from "./EditCategoryModal";
import DeleteCategoryModal from "./DeleteCategoryModal";
import "./CategoryList.scss";

const CategoryList = () => {
	const [categoryList, setCategoryList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPages, setTotalPages] = useState(1);
	const [showEditModal, setShowEditModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [loading, setLoading] = useState(false); // Add loading state

	const handleEditClick = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	};

	const handleDeleteClick = (category) => {
		setSelectedCategory(category);
		setShowDeleteModal(true);
	};

	const handleEditModalClose = () => {
		setShowEditModal(false);
		setSelectedCategory(null);
	};

	const handleDeleteModalClose = () => {
		setShowDeleteModal(false);
		setSelectedCategory(null);
	};

	const handleSaveChanges = async (updatedCategory) => {
		try {
			const payload = {
				id: updatedCategory.id,
				categoryName: updatedCategory.categoryName,
				description: updatedCategory.description,
			};
			const { data } = await UPDATE_CATEGORY(payload);
			if (data.code === 1) {
				toast.success(data.message);
				getCategoryList(currentPage);
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const handleDeleteCategory = async () => {
		try {
			const { data } = await DELETE_CATEGORY({ id: selectedCategory.id });
			if (data.code === 1) {
				toast.success(data.message);
				getCategoryList(currentPage);
				handleDeleteModalClose();
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error(error.message || "An error occurred. Please try again.");
		}
	};

	const getCategoryList = async (page) => {
		setLoading(true); // Start loading
		try {
			const { data } = await CATEGORY_LIST({ page });
			setCategoryList(data.result.categories);
			setTotalPages(Number(data.result.pagination.totalPages));
		} catch (error) {
			if (error.response) {
				const { data } = error.response;
				toast.error(data.message);
			} else {
				toast.error(error.message || "An error occurred. Please try again.");
			}
		} finally {
			setLoading(false); // End loading
		}
	};

	useEffect(() => {
		getCategoryList(currentPage);
	}, [currentPage]);

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};

	return (
		<div className="container p-4">
			<table className="table table-bordered table-striped table-hover">
				<thead>
					<tr>
						<th scope="col">#</th> {/* Serial Number Column */}
						<th scope="col">Category</th>
						<th scope="col">Description</th>
						<th scope="col">Action</th>
					</tr>
				</thead>
				<tbody>
					{loading ? (
						<tr>
							<td colSpan="4" className="text-center">
								Loading...
							</td>
						</tr>
					) : categoryList.length > 0 ? (
						categoryList.map((category, index) => (
							<tr key={category.id}>
								<td>{(currentPage - 1) * categoryList.length + (index + 1)}</td>{" "}
								{/* Calculate Serial Number */}
								<td>{category.categoryName}</td>
								<td>{category.description}</td>
								<td>
									<button
										className="btn btn-primary btnPrimary btn-sm"
										onClick={() => handleEditClick(category)}
										style={{ marginRight: "5px" }}
									>
										Edit
									</button>
									<button
										className="btn btn-danger btn-sm"
										onClick={() => handleDeleteClick(category)}
									>
										Disable
									</button>
								</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="4" className="text-center">
								No categories found.
							</td>
						</tr>
					)}
				</tbody>
			</table>

			{/* Centered Pagination */}
			{totalPages > 1 && (
				<nav>
					<ul className="pagination justify-content-center">
						{[...Array(totalPages).keys()].map((page) => (
							<li
								className={`page-item ${
									page + 1 === currentPage ? "active" : ""
								}`}
								key={page}
							>
								<button
									className="page-link"
									onClick={() => handlePageChange(page + 1)}
								>
									{page + 1}
								</button>
							</li>
						))}
					</ul>
				</nav>
			)}

			{selectedCategory && (
				<EditCategoryModal
					show={showEditModal}
					handleClose={handleEditModalClose}
					categoryData={selectedCategory}
					handleSave={handleSaveChanges}
				/>
			)}

			{selectedCategory && (
				<DeleteCategoryModal
					show={showDeleteModal}
					handleClose={handleDeleteModalClose}
					handleDelete={handleDeleteCategory}
				/>
			)}
		</div>
	);
};

export default CategoryList;
