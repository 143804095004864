import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import {  UPDATE_FAQ, FAQ_CATEGORY_LIST, GET_FAQ_DETAILS } from "../../service/faqApi";

const EditFaq = () => {
	const navigate = useNavigate();
	const { id } = useParams(); // Get the FAQ ID from the URL
	const [formData, setFormData] = useState({
		questiontype: "",
		faqcategoryid: "",
	});
	const [faqCategoryList, setFaqCategoryList] = useState([]);
	const [questions, setQuestions] = useState([{ question: "", answer: "" }]);
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState("");

	// Fetch FAQ details and pre-fill form
	const getFaqDetails = async () => {
		try {
			setIsLoading(true);
			const { data } = await GET_FAQ_DETAILS({id});
			const { result } = data || {};
			const { faqDetails } = result || {};
			setFormData({
				questiontype: faqDetails.questiontype,
				faqcategoryid: faqDetails.faqcategoryid,
			});
			setQuestions(faqDetails.questionanswer || []);
		} catch (error) {
			toast.error(error.response?.data?.message || "Failed to load FAQ details.");
		} finally {
			setIsLoading(false);
		}
	};

	// Fetch FAQ categories
	const getAllFaqCategoryList = async () => {
		try {
			setIsLoading(true);
			const { data } = await FAQ_CATEGORY_LIST({page: 1});
			setFaqCategoryList(data.result.faqCategories);
		} catch (error) {
			toast.error(error.response?.data?.message || "Failed to load FAQ categories.");
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getFaqDetails();
		getAllFaqCategoryList();
	}, [id]);

	const handleChange = (event) => {
		const { name, value } = event.target;
		if (name === "questiontype" && /^[a-z]/.test(value)) {
			setError(
				"Category name is case sensitive and should start with an uppercase letter."
			);
		} else {
			setError("");
		}
		setFormData({
			...formData,
			[name]: value,
		});
	};

	const handleQuestionChange = (index, field, value) => {
		const updatedQuestions = [...questions];
		updatedQuestions[index][field] = value;
		setQuestions(updatedQuestions);
	};

	const addQuestion = () => {
		setQuestions([...questions, { question: "", answer: "" }]);
	};

	const removeQuestion = (index) => {
		const updatedQuestions = questions.filter((_, i) => i !== index);
		setQuestions(updatedQuestions);
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		setIsLoading(true);
		try {
			const payload = { ...formData, questionanswer: JSON.stringify(questions),faqid: id };
			const { data } = await UPDATE_FAQ(payload); // Replace with your API call
			const { code, message } = data;

			if (code === 0) {
				toast.error(message);
				setIsLoading(false);
				return;
			}

			toast.success(message);
			navigate("/dashboard/faq-list");
		} catch (error) {
			toast.error(error.response?.data?.message || "An error occurred.");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="container">
			<div className="row justify-content-center">
				<div className="col-10">
					<div className="form_container mt-4 mb-3">
						<div className="card">
							<div className="card-body">
								<h3 className="card-title text-center">Edit FAQ</h3>
								<form onSubmit={handleSubmit}>
									<div className="form-group row">
										<label htmlFor="question-type" className="col-sm-4 col-form-label">
											Question Type:
										</label>
										<div className="col-sm-8">
											<input
												type="text"
												className="form-control"
												id="question-type"
												name="questiontype"
												placeholder="Enter question type"
												value={formData.questiontype}
												onChange={handleChange}
												required
												disabled={isLoading}
											/>
											{error && <small className="text-danger">{error}</small>}
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="faq-category" className="col-sm-4 col-form-label">
											FAQ Category:
										</label>
										<div className="col-sm-8">
											<select
												id="faq-category"
												name="faqcategoryid"
												className="form-control form-select"
												value={formData.faqcategoryid}
												onChange={handleChange}
												required
												disabled={isLoading}
											>
												<option value="" disabled>
													Select category
												</option>
												{faqCategoryList.map((option) => (
													<option key={option.id} value={option.id}>
														{option.name}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="form-group row">
										<label htmlFor="questions" className="col-sm-4 col-form-label">
											Questions and Answers:
										</label>
										<div className="col-sm-8">
											{questions.map((qa, index) => (
												<div key={index} className="form-group row mt-3">
													<div className="col-sm-5">
														<textarea
															className="form-control"
															placeholder="Enter question"
															value={qa.question}
															onChange={(e) =>
																handleQuestionChange(index, "question", e.target.value)
															}
															disabled={isLoading}
															required
														/>
													</div>
													<div className="col-sm-5">
														<textarea
															className="form-control"
															placeholder="Enter answer"
															value={qa.answer}
															onChange={(e) =>
																handleQuestionChange(index, "answer", e.target.value)
															}
															disabled={isLoading}
															required
														/>
													</div>
													<div className="col-sm-2 text-center">
														<button
															type="button"
															className="btn btn-danger"
															onClick={() => removeQuestion(index)}
															disabled={isLoading}
														>
															-
														</button>
													</div>
												</div>
											))}
										</div>
										<div className="form-group row mt-3">
											<div className="col-sm-12 text-center">
												<button
													type="button"
													className="btn btn-primary"
													onClick={addQuestion}
													disabled={isLoading}
												>
													+ Add Question
												</button>
											</div>
										</div>
									</div>
									<div className="form-group row mt-4">
										<div className="col-sm-4"></div>
										<div className="col-sm-8 button-group">
											<input
												type="submit"
												value={isLoading ? "Updating..." : "Update"}
												className="btn btn-success"
												style={{ width: "150px", marginRight: "10px" }}
												disabled={isLoading}
											/>
											<input
												type="button"
												value="Cancel"
												className="btn btn-secondary"
												style={{ width: "150px" }}
												onClick={() => navigate("/dashboard/faq-list")}
												disabled={isLoading}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditFaq;
