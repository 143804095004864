import { CONSTANT_URL } from "./apiEndPoint";
import { notificationServiceApiCall } from "./notificationService";
import { userServiceApiCall } from "./userService";

// Function to handle query parameters
const buildQueryParams = (params) => {
    return Object.keys(params)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
        .join("&");
};

export const GET_USER_LIST = async (payload) => {
    const queryParams = buildQueryParams({
        page: payload.page,
        search: payload.search || "" // Include search if it exists
    });
    const url = `${CONSTANT_URL.USER_LIST}?${queryParams}`;
    return await userServiceApiCall("get", url);
};

export const PARTNER_LIST = async (payload) => {
    const queryParams = buildQueryParams({
        page: payload.page,
        search: payload.search || "" // Include search if it exists
    });
    const url = `${CONSTANT_URL.PARTNER_LIST}?${queryParams}`;
    return await userServiceApiCall("get", url);
};

export const BULK_EMAIL = async (payload) => {
    return await notificationServiceApiCall("post", CONSTANT_URL.BULK_EMAIL, payload);
};

export const PROCESS_EMAIL_QUEUE = async () => {
    return await notificationServiceApiCall("get", CONSTANT_URL.PROCESS_EMAIL_QUEUE);
};

export const REPLY_TO_CUTOMER_QUERY = async (payload) => {
    return await notificationServiceApiCall("post", CONSTANT_URL.REPLY_TO_CUTOMER_QUERY, payload);
};

